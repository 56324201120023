import { highestOrderBitContent } from './highest-order-bit';

export const blogContent = {
    'highest-order-bit': {
        title: 'Highest Order Bit',
        content: highestOrderBitContent,
        meta: {
            viewport: 'width=device-width, initial-scale=1.0',
            mobileStyles: {
                fontSize: '16px', // Readable font size for mobile
                lineHeight: '1.6', // Increased line height for readability
                padding: '15px', // Add padding for touch targets
                images: {
                    maxWidth: '100%', // Make images responsive
                    height: 'auto'
                }
            }
        }
    },
    // ... other blog posts
};