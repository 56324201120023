import React from 'react';
import Section from './Section';
import { Link } from 'react-router-dom';

function Writing() {
  return (
    <div className="writings-container">
      <Section
        content={
          <React.Fragment>
            <nav className="writings-nav">
              <ul className="writings-list">
                <li>
                  <Link to="/writing/highest-order-bit">
                    <div>Highest Order Bit</div>
                    <span>Published: December 25th, 2024</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </React.Fragment>
        }
        id="writings"
        className="section writings"
      />
    </div>
  );
}

export default Writing;