import React from 'react';

const Section = ({ title, content, id, className }) => {
  return (
    <section id={id} className={className}>
      {content && <div>{content}</div>}
    </section>
  );
};

export default Section;