import React from 'react';

function ProfileImage() {
  return (
    <div className="profile-image">
      <img src={'images/profile-pic.jpg'} alt="PK" />
    </div>
  );
}

export default ProfileImage;